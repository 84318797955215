import React from "react";
import { Container, Button, Row } from "react-bootstrap";
import CardsActu from "../../cards";
import { Link } from "react-router-dom";
import "./actu.scss";

const Actu = () => {
  return (
    <div className="coloredBG">
      <Container >
      <Row>
        <h2 className="title text-center">Actualités</h2>
        <p className="subTitle">
            Retrouvez nos dernières actualités produits et services
          </p>
        <CardsActu
          text="Téléchargez la fiche d'information détaillée"
          title="Nouveau produit : TALIQ ENERGIE"
          img={require("../../../assets/actu/actu_taliq_energie.png")}
          link={"/pdf/FicheProduitTaliqENERGIE.pdf"}
        />
        <CardsActu
          text="Découvrez dès maintenant l'application TALIAN."
          title="Nouveauté : APPLI TALIAN"
          img={require("../../../assets/actu/actu_appli.jpg")}
          link={"/actualites/app-info"}
        />
        <CardsActu
          text="Découvrez le dernier bulletin technique disponible."
          title="Bulletin technique Mars 2025"
          img={require("../../../assets/actu/actu_bule.jpg")}
          link={"/pdf/mars_2025.pdf"}
        />
      </Row>
      <Link to="/actualites">
        <Button variant="" className="button mt-5">
          Toutes nos actualités
        </Button>
      </Link>
    </Container>
    </div>
  );
};

export default Actu;
